.landing {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &_error {
    width: 80%;
    margin-bottom: 2rem!important;
  }
}