html {
  height: -webkit-fill-available;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: #EEE;
  .App {
    min-height: 100vh;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 5rem auto;
    grid-template-areas: 
      "header"
      "container"
    ;
    .container {
      grid-area: container;
    }
  }
  
}