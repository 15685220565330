.import_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 2.5rem;
  path {
    pointer-events: none;
  }
  &_box {
    padding: 2rem;
    position: relative;
    width: 250px;
    height: 250px;
  }
  &_button {
    padding: 0;
    margin: 0;
  }
  &_svg {
    font-size: 250px!important;
  }
  &_save {
    position: absolute;
    bottom: 20px;
    right: 22px;
  }
  &_header {
    position: absolute;
    top: 22.5px;
    width: 300px;
  }
}