.cdrs {
  height: calc(100% - 4rem);
  padding: 2rem;
  display: flex;
  align-items: center;

  flex-direction: column;
  &_pagination {
    padding: 4rem 0;
  }
  &_pagination--top {
    width: 499px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .cdrs_pagination--element {
      margin-right: auto;
    }
    .selectCdrsListSize {
      position: absolute;
      right: 0;
      bottom: 30%;
    }
  }
  &_date {
    text-align: center;
    padding-bottom: 2rem!important;
  }
  &_card-content {
    min-height: 424.010px;
  }
  &_card-loader {
    min-height: 424.01px;
    display: flex;
    align-items: center;
  }
  &_card-content:last-child {
    padding-bottom: 4px!important;
  }
  &_container {
    justify-content: center;
  }
  .cdr-filter {
    padding: 0.5rem 2rem;
    flex-direction: column;
    font-size: 52px;
  }
}

@media screen and (min-width: 998px) {
  .cdrs_pagination--top {
    width: 998px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 1497px) {
  .cdrs_pagination--top {
    width: 1497px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 1996px) {
  .cdrs_pagination--top {
    width: 1996px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 2495px) {
  .cdrs_pagination--top {
    width: 2495px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 2994px) {
  .cdrs_pagination--top {
    width: 2994px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 3493px) {
  .cdrs_pagination--top {
    width: 3493px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}

@media screen and (min-width: 3992px) {
  .cdrs_pagination--top {
    width: 3992px;
    .cdrs_pagination--element {
      margin: auto;
    }
  }
}