.import {
  height: 100%;
  display: flex;
  flex-direction: column;
  &_date {
    text-align: center;
    font-size: 70px;
    margin: 0;
    padding: 6rem 0;
  }
  &_container {
    width: 650px;
    margin: auto;
    text-align: center;
  }
}