.search-input {
  display: flex;
  &_autocomplete > * > * {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  &_toggle--name {
    border-left-width: 0!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }
}